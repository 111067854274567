<template>
    <div class="view pa24">
        <el-form :model="ruleForm" ref="ruleForm" label-position="top" class="demo-ruleForm">
            <el-form-item label="公众号appId" prop="appId">
                <el-col :lg="11">
                    <el-input placeholder="请输入公众号appId" v-model="ruleForm.appid"></el-input>
                </el-col>
            </el-form-item>
            <el-form-item label="公众号密匙（AppSecret）" prop="appId">
                <el-col :lg="11">
                    <el-input placeholder="请输入公众号密匙" v-model="ruleForm.appSecret"></el-input>
                </el-col>
            </el-form-item>
            <el-form-item>
                <el-upload class="upload-demo" :action="uploadUrl" :on-preview="handlePreview" :on-remove="handleRemove"
                    multiple :limit="1" :on-exceed="handleExceed" :file-list="fileList" accept=".txt" :auto-upload="false"
                    :on-success="handleSuccess" :on-change="changeUpload">
                    <el-button size="small" type="primary">点击上传</el-button>
                    <div slot="tip" class="el-upload__tip">*只能上传由公众号添加网页授权域名时下载的【MP_verify_****.txt】文件</div>
                </el-upload>
            </el-form-item>
            <el-form-item>
                <el-col :lg="24">
                    <el-button type="primary" @click="submit">提交</el-button>
                    <el-button type="primary">重置授权</el-button>
                </el-col>
            </el-form-item>

        </el-form>
    </div>
</template>
  
<script>

import { appletSaveInfo } from "@/api/wechat";

export default {
    data() {
        return {
            ruleForm: {},
            fileList: [],
            uploadUrl: this.$store.state.uploadingUrl,
            text_file: '',
        };
    },
    created() {
    },
    computed: {
        checkAppSecret() {
            return this.ruleForm.AppSecret ? '' : 'password'
        },
    },
    methods: {
        submit() {
            this.ruleForm.companyId = localStorage.getItem('companyId') || '';
            if (!this.ruleForm.companyId) {
                this.$message.warning('未获取到公司Id！');
                return;
            }
            if (!this.text_file) {
                this.$message.warning('请上传文件！');
                return;
            }
            if (!this.ruleForm.appid) {
                this.$message.warning('请填写公众号appId！');
                return;
            }
            if (!this.ruleForm.appSecret) {
                this.$message.warning('请填写公众号密匙！');
                return;
            }
            // this.ruleForm.MultipartFile = this.text_file;
            this.ruleForm.fileName = this.text_file.name;
            console.log(this.ruleForm,'提交数据');
            appletSaveInfo(this.ruleForm).then(res=>{})
        },
        changeUpload(file) {
            console.log(file);
            if (file && file.name && file.raw) {
                let na = file.name;
                na = na.split('_');

                if (na[0] !== 'MP' || na[1] !== 'verify') {
                    this.$message.error('上传文件格式错误！');
                    this.fileList = [];
                    return false
                } else {
                    let last = na[2].split('.');
                    if (last[0].length !== 16 || last[last.length - 1] !== 'txt') {
                        this.$message.error('上传文件格式错误！');
                        this.fileList = [];
                        return false
                    }
                }
            } else {
                this.$message.error('上传文件格式错误！');
                this.fileList = [];
                return false;
            }
            this.text_file = file.raw;
        },
        handleRemove(file, fileList) {
            this.text_file = '';
        },
        handlePreview(file) {
        },
        handleExceed(files, fileList) {
            this.$message.warning('只能上传一个文件');
        },
        handleSuccess(file, fileList) {
            console.log(file, '成功')
        }
    }
};
</script>
  
<style lang="scss" scoped>
/deep/.el-upload--text {
    background-color: #fff;
    border: 0px dashed #d9d9d9;
    border-radius: 6px;
    box-sizing: border-box;
    width: 84px;
    height: 34px;
    text-align: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
</style>
  